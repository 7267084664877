<template>
	<div>
		<!-- 第一部分：录音测试 -->
		<div id="micStep1" class="step">
			<h2>麦克风录音测试</h2>
			<!-- <p id="countdown">倒计时: 5秒</p> -->
			<!-- 点击录音图标 -->
			<el-button id="recordButton" type="primary">录制声音</el-button>
		</div>
		<img id="loadingImg" style="display:none;" class="loading-img" src="../../assets/ket-paper/play.gif" />

		<!-- 第二部分：播放录音和选择能听见或听不见 -->
		<div id="micStep2" class="step" style="display:none;">
			<h2>麦克风录音测试</h2>
			<!-- 播放录音的按钮 -->
			<el-button id="playRecording" type="primary">播放录音</el-button>
			<!-- 显示播放完录音后的选择按钮 -->
			<div class="btn-group" style="margin-top:10px;">
				<el-button id="canHearMic" style="display:none;" type="success">能听见</el-button>
				<el-button id="cantHearMic" style="display:none;" type="warning">听不见</el-button>
			</div>
		</div>

		<!-- 第三部分：重测和说明文字 -->
		<div id="micStep3" class="step" style="display:none;">
			<h2>麦克风录音测试</h2>
			<div class="info">
				<p>1. 检查是否打开了网页对麦克风的权限</p>
				<p>2. 检查电脑系统是否静音或麦克风音量太小</p>
			</div>
			<el-button id="retryButton" type="primary">重测</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: [],
		data() {
			return {

			};
		},
		mounted() {
			let that = this;
			let countdown = 5;
			let audioBlob;
			let audioURL;
			let mediaRecorder;
			let audioChunks = [];
			let isRecording = false; // 标识当前是否正在录音

			// 获取元素
			const recordButton = document.getElementById("recordButton");
			const loadingImg = document.getElementById("loadingImg");
			const countdownDisplay = document.getElementById("countdown");
			const micStep1 = document.getElementById("micStep1");
			const micStep2 = document.getElementById("micStep2");
			const micStep3 = document.getElementById("micStep3");
			const playRecordingButton = document.getElementById("playRecording");
			const canHearMicButton = document.getElementById("canHearMic");
			const cantHearMicButton = document.getElementById("cantHearMic");
			const retryButton = document.getElementById("retryButton");

			// 请求麦克风权限并处理
			let userMediaStream;
			navigator.mediaDevices.getUserMedia({
					audio: true
				})
				.then(function(stream) {
					userMediaStream = stream; // 将流保存，后续录音时使用
					console.log("麦克风权限已授权");
				})
				.catch(function(err) {
					console.log("麦克风权限错误: ", err);
				});

			// 录音按钮点击事件
			recordButton.addEventListener("click", function() {
				// if (isRecording) return; // 如果已经在录音中，防止重复点击

				// // 开始倒计时
				// countdownDisplay.innerHTML = `倒计时: ${countdown}秒`;
				// const countdownInterval = setInterval(function() {
				// 	countdown--;
				// 	countdownDisplay.innerHTML = `倒计时: ${countdown}秒`;

				// 	if (countdown === 0) {
				// 		clearInterval(countdownInterval);
				// 		startRecording();
				// 	}
				// }, 1000);

				startRecording();
			});

			// 开始录音
			function startRecording() {
				micStep1.style.display = "none"; // 隐藏第一部分
				loadingImg.style.display = "block";

				isRecording = true; // 标记为正在录音

				// 创建媒体录音对象并开始录音
				mediaRecorder = new MediaRecorder(userMediaStream);
				mediaRecorder.start();

				mediaRecorder.ondataavailable = function(event) {
					audioChunks.push(event.data);
				};

				mediaRecorder.onstop = function() {
					audioBlob = new Blob(audioChunks, {
						type: 'audio/wav'
					});
					audioURL = URL.createObjectURL(audioBlob); // 创建音频URL
					loadingImg.style.display = "none";
					micStep2.style.display = "flex"; // 显示第二部分
					playRecordingButton.style.display = "block"; // 隐藏播放按钮
				};

				// 5秒钟后停止录音
				setTimeout(function() {
					mediaRecorder.stop();
				}, 5000); // 假设录音时间为5秒
			}

			// 播放录音按钮点击事件
			playRecordingButton.addEventListener("click", function() {
				loadingImg.style.display = "block";
				micStep2.style.display = "none"; // 显示第二部分
				const audioPlayer = new Audio(audioURL); // 使用刚录制的音频URL
				audioPlayer.play(); // 播放录音

				// 播放完后显示能听见和听不见按钮
				audioPlayer.onended = function() {
					micStep2.style.display = "flex"; // 显示第二部分
					canHearMicButton.style.display = "block"; // 显示"能听见"按钮
					cantHearMicButton.style.display = "block"; // 显示"听不见"按钮
					loadingImg.style.display = "none";
				};

			});

			// 点击"能听见"按钮，跳转到正式考试页面
			canHearMicButton.addEventListener("click", function() {
				that.$emit("changeTestType", 2);
			});

			// 点击"听不见"按钮，显示重测按钮和说明文字
			cantHearMicButton.addEventListener("click", function() {
				micStep2.style.display = "none"; // 隐藏第二部分
				micStep3.style.display = "flex"; // 显示第三部分
			});

			// 点击"重测"按钮，重新开始录音
			retryButton.addEventListener("click", function() {
				micStep3.style.display = "none";
				micStep1.style.display = "flex";
				countdown = 5; // 重置倒计时
				audioChunks = []; // 清空录音文件
				playRecordingButton.style.display = "none"; // 隐藏播放按钮
				canHearMicButton.style.display = "none"; // 隐藏能听见按钮
				cantHearMicButton.style.display = "none"; // 隐藏听不见按钮
				isRecording = false; // 录音状态重置
			});
		},
		methods: {

		}
	};
</script>

<style scoped lang="scss">
	/* 你可以根据需要自定义样式 */
	.step {
		background-color: #fff;
		border-radius: 10px;
		width: 800px;
		height: 600px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		img {
			cursor: pointer;
		}

		h2 {
			margin-top: 0;
			margin-bottom: 80px;
		}

		.btn-group {
			display: flex;
			justify-content: space-around;
		}

		.info {
			margin-bottom: 80px;
		}
	}

	.loading-img {
		width: 600px;
	}
</style>