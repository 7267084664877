<template>
  <div id="child-wrap" class="child-wrap">
    <div v-if="bPdfImagesAvailable" class="flex-2" style="overflow: scroll">
      <template v-for="(jpg, idx) in res.data.questions_pdf_images">
        <hr v-if="idx>0"/>
        <img :src="jpg" style="width:100%;">
      </template>
    </div>
    <div v-else class="flex-1" id="adobe-dc-view"></div>
    <div class="test-paper" ref="testPaper">
      <div class="banner">
        <!-- 		  <audio id="audio" controls></audio> -->
        <!--img class="banner-title" src="../../assets/hunan-paper/title.png" /-->
        <img class="banner-title" style="width: 100%" :src="title_img_url">
        <img class="banner-timer" src="../../assets/hunan-paper/timer.png" />
		<img class="banner-photo" v-if="stateType!='done'&&paperData.card_img_num" @click="openAllCard" src="../../assets/hunan-paper/photo-card.png" />
        <div class="time-cont">{{timeString}}</div>
        <img class="start-img" v-if="stateType!='done'" @click="handleSubmit" :src="uiStyleCfg?.confirmBtnImg" />
        <!--div class="banner-text">
          <div>{{first_title}}</div>
          <ul>
            <li>{{second_title}}</li>
          </ul>
        </div-->
      </div>
	  <templete v-if="stateType=='done'">
		  <div class="ket" v-if="res.data.ui_style_set=='ZLHY@A2KET-LISTENING'">
			  <div class="score-cont">
				<div class="score">
				  <img src="../../assets/ket-paper/listening.png" class="score-img">
				  <div class="score-text">{{paperData.listening_score}}</div>
					<div class="score-text-org">{{paperData.listening_std_level}}</div>
				</div>
			  </div>
			  <div class="baogao-cont">
				<img src="../../assets/ket-paper/baogao1.png" class="score-img" style="margin: 3px;" @click="openBaogao">
				<img v-if="res.data.analysis_pdf_images && res.data.analysis_pdf_images.length>0"
					 src="../../assets/ket-paper/jiaoben.png" class="score-img" style="margin: 3px;" @click="openExplainImages">
			  </div>
		  </div>
		  <div class="ket-read" v-else-if="res.data.ui_style_set=='ZLHY@A2KET-READING&WRITING'">
			  <div class="score-cont">
				<div class="score-read">
				  <img src="../../assets/ket-paper/reading.png" class="reading">
				  <div class="score-text">{{paperData.reading_score}}</div>
					<div class="score-text-org">{{paperData.reading_std_level}}</div>
				</div>
				<div class="score-img">
					<img src="../../assets/ket-paper/baogao1.png" style="margin: 3px;" @click="openBaogao">
				</div>
			  </div>
			  <div class="baogao-cont">
				<img src="../../assets/ket-paper/writing.png" class="writing">
				<div class="score-text">{{paperData.writing_score}}</div>
				<div class="score-text-org">{{paperData.writing_std_level}}</div>
			  </div>
		  </div>
		  <div v-else>
		  		  <div class="score-cont" :style="{backgroundColor:uiStyleCfg?.bgColor}">
		  			<div class="score">
		  			  <img :src="uiStyleCfg?.scoreImg" class="score-img">
		  			  <div class="score-text">{{paperData.user_score}}</div>
		  			</div>
		  			<div class="score">
		  			  <img :src="uiStyleCfg?.totalImg" class="score-img">
		  			  <div class="score-text">{{paperData.total_score}}</div>
		  			</div>
		  		  </div>
		  		  <div class="baogao-cont" :style="{backgroundColor:uiStyleCfg?.bgColor}">
		  		    <img v-if="uiStyleCfg?.baogaoBtnImg" :src="uiStyleCfg?.baogaoBtnImg" class="score-img" style="margin: 3px;" @click="openBaogao">
		  		    <img v-if="uiStyleCfg?.explainBtnImg && res.data.analysis_pdf_images && res.data.analysis_pdf_images.length>0"
		  		         :src="uiStyleCfg?.explainBtnImg" class="score-img" style="margin: 3px;" @click="openExplainImages">
		  		  </div>
		  </div>
	  </templete>
      <div class="paper-cont" :style="{backgroundColor:uiStyleCfg?.bgColor}">
        <div class="paper-ul">
          <div class="ul-item cur" @click="handleCheck(item)" :style="{backgroundColor:uiStyleCfg?.tabBgColor,color:uiStyleCfg?.tabFontColor}" :class="item.type_code!=type_code?'noactUl':''" v-for="(item,index) in dataList" :key="index">{{item.type_name}}</div>
        </div>
        <div class="paper-list">
          <div class="list-item" v-for="(item,index) in questionsObjext&&questionsObjext.questions" :key="index">
            <!--div class="areaClass" v-if="questionsObjext&&(questionsObjext.type_code == 'writing-writing'||questionsObjext.type_code == 'writing-complete'||questionsObjext.type_code == 'writing-answer')" comment="textarea题"-->
            <div v-if="questionsObjext&&(item.label == 'AI-W'||item.label == 'AI-W-C'||item.label == 'AI-W-A'||item.label == 'science_open')" class="areaClass" comment="textarea题">
              <div v-if="stateType=='done'" class="item-btn cur areaAnswer" comment="已交卷">
<!--                {{item.my_answer}} -->
				<MarkdownRenderer :content="item.my_answer" />
                <img class="pf" v-if="item.true_or_false == 1" :src="uiStyleCfg?.trueImg" />
                <img class="pf" v-else-if="item.true_or_false == 0" :src="uiStyleCfg?.falseImg" />
              </div>


<!--              <textarea spellcheck="false" v-else comment="未交卷" class="item-btn cur areaInput" :id="'text-'+item.id" :placeholder="item.preset_text" v-model="textQuestionValues[item.id]"/> -->
              <div style="position:relative;width:100%;height:100%" v-else>
				  <latex :id="'text-'+item.id" ref="latex" @click="openCard(item)" @clickMd="clickMd(item.id)" :foo.sync="textQuestionValues[item.id]" :examinee_id="examinee_id" :paper_id="paper_id" :question_id="actVoice" />
				  <!-- <MarkdownRendererHtml class="latexMd" :class="mdAct?'latexMdAct':''" :content="textQuestionValues[item.id]" /> -->
			  </div>
							<div class="hunanjx" :style="{backgroundColor:uiStyleCfg?.jxBgColor,color:uiStyleCfg?.jxFontColor}" v-if="item.explain_v2&&stateType=='done'" @click="checkTitNew(item)">
							  题目解析
							</div>
              <div class="hunanjx" :style="{backgroundColor:uiStyleCfg?.jxBgColor,color:uiStyleCfg?.jxFontColor}" v-else-if="(item.explain||item.voice_explain)&&stateType=='done'" @click="checkTit(item)">
                题目解析
              </div>

			 <!-- <MarkdownRenderer :content="testgpt" /> -->
			 <!-- <textarea spellcheck="false" placeholder="这里粘贴gpt" v-model="testgpt"/ -->

            </div>
            <div v-else-if="item.label == 'choose'" class="abc" :style="{color:uiStyleCfg?.itemFontColor,borderColor:uiStyleCfg?.itemBorderColor}">
              <div class="item-btn item-index" :style="{backgroundColor:uiStyleCfg?.labelBgColor}">{{item.question_number}}</div>
              <div v-for="(subItem,subIndex) in setOptional(item.optional)"
                   class="item-btn flex-1">
                {{subItem}}
                <div class="sel-btn" :style="{backgroundColor:isSelected(item.my_answer,subItem)?uiStyleCfg?.itemBgColor:''}" @click="selectItem(subItem,item)"></div>
								<templete v-if="stateType=='done'">
									<!-- 正确答案且被选中的选项，显示对号 -->
									<img class="pf" v-if="isCorrectAndSelected(item, subItem)" :src="uiStyleCfg.trueImg" />
									<!-- 多选题：选错的选项或未选择的正确答案，显示错号 -->
									<!-- 通过判断answer中选项数量来确定是否为多选 -->
									<img class="pf" v-else-if="(isMultipleChoice(item) && (isSelectedOption(item, subItem) || isCorrectOption(item, subItem))) ||(!isMultipleChoice(item) && isCorrectOption(item, subItem) && !isSelectedOption(item, subItem))":src="uiStyleCfg.falseImg" />
								</templete>
              </div>
							<div class="hunanjx" :style="{backgroundColor:uiStyleCfg?.jxBgColor,color:uiStyleCfg?.jxFontColor}" v-if="item.explain_v2&&stateType=='done'" @click="checkTitNew(item)">
							  题目解析
							</div>
              <div class="hunanjx" :style="{backgroundColor:uiStyleCfg?.jxBgColor,color:uiStyleCfg?.jxFontColor}" v-else-if="(item.explain||item.voice_explain)&&stateType=='done'" @click="checkTit(item)">
                题目解析
              </div>
            </div>
            <div v-else-if="item.label==='text'||item.label==='answer-text'||item.label==='science_text'" class="abc" comment="text 代表填空题">
              <div class="item-btn item-index tkIndex" @click="openCard(item)" :style="{backgroundColor:uiStyleCfg?.labelBgColor}">{{item.question_number}}</div>
              <div v-if="stateType=='done'" class="item-btn cur pf-cont" comment="已交卷">
                {{item.my_answer}}
                <template v-if="item.label==='text'">
                  <img class="pf" v-if="item.true_or_false == 1" :src="uiStyleCfg?.trueImg" />
                  <img class="pf" v-else-if="item.true_or_false == 0" :src="uiStyleCfg?.falseImg" />
                </template>
              </div>
			  <MarkdownRenderer v-if="stateType!='done'" style="flex:1;cursor: pointer;" @click.native="mdEdit(item)" :content="textQuestionValues[item.id]" />
              <!-- <input v-if="stateType!='done'" comment="未交卷" type="text" class="item-btn cur flex-1 tkInput" :id="'text-'+item.id" v-model="textQuestionValues[item.id]"/> -->
              <div class="hunanjx" :style="{backgroundColor:uiStyleCfg?.jxBgColor,color:uiStyleCfg?.jxFontColor}" v-if="item.explain_v2&&stateType=='done'" @click="checkTitNew(item)">
                题目解析
              </div>
							<div class="hunanjx" :style="{backgroundColor:uiStyleCfg?.jxBgColor,color:uiStyleCfg?.jxFontColor}" v-else-if="(item.explain||item.voice_explain)&&stateType=='done'" @click="checkTit(item)">
                题目解析
              </div>
            </div>
            <div class="s60" v-else>
              <template v-if="stateType=='done'">
                <img class="" v-if="item.voice_url&&!item.isPlay" @click="clickPlay(item)" src="../../assets/hunan-paper/play.png" alt="">
                <img class="" v-else-if="item.voice_url&&item.isPlay" @click="clickStop(item)" src="../../assets/hunan-paper/stop.png" alt="">
              </template>
              <template v-else>
                <div class="voiceBtn" v-if="actVoice===item.id">
                  <el-progress class="bor" type="circle" :width="progressWidth" :show-text="false" :percentage="item.percentage"></el-progress>
                  <img class="actimg" @click="stopVoice(item)" :src="uiStyleCfg?.itemStopBtnImg" alt="">
                </div>
                <img v-else @click="startVoice(item)" class="list-img" :class="uploadIdArr.indexOf(item.id)!=-1?'checkImg':''" :src="uiStyleCfg?.itemPlayBtnImg" alt="">
              </template>
              <div class="s60-txt flex-1">{{item.question}}</div>
							<div class="hunanjx" :style="{backgroundColor:uiStyleCfg?.jxBgColor,color:uiStyleCfg?.jxFontColor}" v-if="item.explain_v2&&stateType=='done'" @click="checkTitNew(item)">
							  题目解析
							</div>
              <div class="hunanjx" :style="{backgroundColor:uiStyleCfg?.jxBgColor,color:uiStyleCfg?.jxFontColor}" v-else-if="(item.explain||item.voice_explain)&&stateType=='done'" @click="checkTit(item)">
                题目解析
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="toggleStartContainer" v-if="!toggleStart&&stateType!='done'" @click="startTest">
      <img class="start" src="../../assets/hunan-paper/start.png" />
    </div>
    <div class="modal modals" id="ScantronLoading" v-if="ScantronLoading">
      <img style="width:60%" :src="uiStyleCfg?.scoringTitleImg||require('../../assets/ScantronLoading1.png')" />
      <img class="" :src="uiStyleCfg?.scoringLogoImg||require('../../assets/ScantronLoading2.gif')" />
    </div>
    <audio ref="audioPlayer" @ended="handleAudioEnded">
      <source src="" type="audio/mp3">
    </audio>

    <el-dialog
        title=""
        :visible.sync="robotVisible"
				:show-close="false"
        custom-class="robot-window"
        @close="closeRobot"
		v-dialogDrag
		v-dialogSize
        >
				<div class="close-btn" @click="robotVisible = false"></div>
      <div class="robot-wrap">
        <div class="img-cont">
					<img class="play" v-if="!isRobotPlay" @click="robotPlay()" src="../../assets/play.png" alt="">
					<img class="pause" v-else @click="robotPause()" src="../../assets/pause.png" alt="">
					<img class="stop" @click="closeRobot()"  src="../../assets/stop.png" alt="">
          <img class="robot-img" :src="uiStyleCfg?.robotImg" />
        </div>
				<div class="robot-text">
					<MarkdownRenderer class="blackBoard" ref="blackBoard" :content="blackBoard" />
					<div class="displayText" ref="displayText">{{mediaText}}</div>
				</div>
      </div>
    </el-dialog>
    <el-dialog
        title="报告"
        :visible.sync="baogaoVisibleOld"
        custom-class="baogao-window"
        @close="baogaoVisible = false"
        width="60vh">
      <div class="baogao-wrap" :class="`Listening-bg`" :style="{backgroundImage:`url(${uiStyleCfg?.baogaoBgImg})`}">
        <img class="baogaoLogo" :src="uiStyleCfg?.baogaoLogoImg" alt="">
        <div class="wel1">亲爱的{{baogaoData.nickname}}同学，恭喜你完成了 当前的考试！</div>
        <div class="wel2">这是你在这份试卷中的能力统计：</div>
        <div class="ans1">与听力能力有关的题目一共{{baogaoData?.abilities?.listening?.total}}道，你做对了{{baogaoData?.abilities?.listening?.correct}}道，正确率为{{calc(baogaoData?.abilities?.listening?.correct,baogaoData?.abilities?.listening?.total)}}%</div>
        <div class="ans2">与阅读能力有关的题目一共{{baogaoData?.abilities?.reading?.total}}道，你做对了{{baogaoData?.abilities?.reading?.correct}}道，正确率为{{calc(baogaoData?.abilities?.reading?.correct,baogaoData?.abilities?.reading?.total)}}%</div>
        <div class="ans3">与词汇能力有关的题目一共{{baogaoData?.abilities?.vocabulary?.total}}道，你做对了{{baogaoData?.abilities?.vocabulary?.correct}}道，正确率为{{calc(baogaoData?.abilities?.vocabulary?.correct,baogaoData?.abilities?.vocabulary?.total)}}%</div>
        <div class="ans4">与语法能力有关的题目一共{{baogaoData?.abilities?.grammar?.total}}道，你做对了{{baogaoData?.abilities?.grammar?.correct}}道，正确率为{{calc(baogaoData?.abilities?.grammar?.correct,baogaoData?.abilities?.grammar?.total)}}%</div>
        <ul>
          <li class="level1">{{baogaoData?.abilities?.listening?.comment}}</li>
          <li class="level2">{{baogaoData?.abilities?.reading?.comment}}</li>
          <li class="level3">{{baogaoData?.abilities?.vocabulary?.comment}}</li>
          <li class="level4">{{baogaoData?.abilities?.grammar?.comment}}</li>
        </ul>
        <div class="end1">完成全部三个部分的考试，你还能看到更完整的能力评估。</div>
      </div>
    </el-dialog>

    <el-dialog
        title="报告V2"
        :visible.sync="baogaoVisible"
        custom-class="baogao-window"
        @close="baogaoVisible = false"
        width="60vh">
      <div class="baogao-wrap" :class="`Listening-bg`" :style="{backgroundImage:`url(${uiStyleCfg?.baogaoBgImg})`}">
        <div style="position:absolute;left:0%;top:15%;width:100%;text-align:center;font-size:30px;font-weight:bolder;color:rgb(175,15,25)">
          {{uiStyleCfg?.baogaoTitle}}
        </div>
        <img :src="uiStyleCfg?.baogaoLogoImg" alt="baogaoLogo"
             style="position:absolute; left:50%;top:28%;width:20%;transform: translate(-50%, -50%);">
        <div class="wel1">亲爱的{{baogaoDataOrig?.nickname}}同学，恭喜你完成了 当前的考试！</div>
        <div class="wel2">这是你在这份试卷中的能力统计：</div>
        <div style="position:absolute;left:0%;top:42%;width:100%;height:26%">
          <div v-for="(ability,aIdx) in baogaoDataOrig?.abilities" style="position:absolute;left:0%" :style="{top:(aIdx*30)+'%'}">
            <div style="position:relative; display: flex; align-items: center; font-weight: bolder; font-size:15px; color:rgb(175,15,25)">
              <img :src="uiStyleCfg?.baogaoPointImg" style="height:20px;"/>
              <span style="margin-left:3px">{{ability.abilityName}}</span>
            </div>
            <div style="position:relative;margin-top:6px">{{ability.commentScore}}</div>
          </div>
        </div>
        <div style="position:absolute; left:0%;top:73%;width:75%;height:11%">
          <div style="position:absolute; display: flex; align-items: center; font-weight: bolder; font-size:15px; color:rgb(175,15,25)">
            <img :src="uiStyleCfg?.baogaoPointImg" style="height:20px;"/>
            <span style="margin-left:3px">根据你在这套试卷中的表现：</span>
          </div>
          <div style="overflow-y: scroll; position: relative; top:25px; height:90%">
            <p v-for="(ability,aIdx) in baogaoDataOrig?.abilities" style="margin-top: 5px;">{{ability.comment}}</p>
          </div>
        </div>
        <div class="end1">完成全部三个部分的考试，你还能看到更完整的能力评估。</div>
      </div>
    </el-dialog>

    <el-dialog class="jiaoben-window" :visible.sync="explainImagesVisible" @close="explainImagesVisible=false"
               id="idExplainImagesVisible"
               top="5vh"
    >
      <div style="overflow:scroll;position: absolute;height:100%;">
        <template v-for="(jpg, idx) in res.data.analysis_pdf_images">
          <hr v-if="idx>0"/>
          <img :src="jpg" style="width:100%;">
        </template>
      </div>
    </el-dialog>

	 <!-- 弹窗：显示答题卡图片上传区域 -->
	  <el-dialog
	    title="上传答题卡图片"
	    :visible.sync="cardVisible"
	    custom-class="upload-card"
	    @close="cardVisible = false"
	    width="800px"
	  >
	    <div class="card-wrap">
	      <!-- 遍历 cardnum 数组，点击某个图片时触发 openUpload 方法 -->
				<div class="card-item" v-for="(item, index) in cardnum" :key="index">
					<div class="card-no">序号：{{index+1}}</div>
					<img
					  @click="openUpload(index)"
					  :src="item.img || require('../../assets/hunan-paper/card-logo.png')"
					  alt=""
					/>
				</div>
	    </div>
			<el-button class="checkCard" type="primary" v-if="cardDataNum.length>0" @click="checkCard">使用</el-button>
	  </el-dialog>

  <!-- 上传方式选择弹窗 -->
    <el-dialog
      title="选择上传方式"
      :visible.sync="uploadVisible"
      custom-class="upload-card"
      @close="uploadVisible = false"
      width="400px"
    >
      <div class="upload-wrap">
        <img src="../../assets/hunan-paper/camera.png" alt="camera" @click="selectUpload('camera')" />
        <img src="../../assets/hunan-paper/picture.png" alt="picture" @click="selectUpload('picture')" />
        <img src="../../assets/hunan-paper/pasteboard.png" alt="pasteboard" @click="selectUpload('pasteboard')" />
      </div>
    </el-dialog>

    <!-- 相机拍照弹窗 -->
    <el-dialog
      title="相机拍摄"
      :visible.sync="cameraVisible"
      custom-class="camera-dialog"
      @close="closeCamera"
      width="600px"
    >
      <div class="camera-container">
        <video style="width: -webkit-fill-available;" ref="video" autoplay></video> <!-- 实时摄像头画面 -->
        <el-button type="primary" @click="capturePhoto">拍照</el-button> <!-- 新增快门按钮 -->
      </div>
    </el-dialog>

    <!-- 裁剪框及上传操作弹窗 -->
    <el-dialog
      title="裁剪图片"
      :visible.sync="cropVisible"
      custom-class="upload-card"
      @close="cropVisible = false"
      width="600px"
    >
      <div class="crop-container">
        <img ref="cropperImage" alt="待裁剪图片" style="max-width: 100%;" />
      </div>
      <div class="crop-actions" style="margin-top: 10px;">
        <el-button type="primary" @click="cropAndUpload">裁剪并上传</el-button>
        <el-button @click="cancelCrop">取消</el-button>
      </div>
    </el-dialog>

	  <el-dialog
	    title="修改markdown文本"
	    :visible.sync="mdEditVisible"
	    custom-class="upload-card"
	    @close="mdEditVisible = false"
	    width="400px"
	  >
	    <el-input
	      type="textarea"
	      :rows="10"
	      placeholder="手动修改markdown文本"
	      v-model="inputContent"
	    ></el-input>

	    <!-- 确认按钮 -->
	    <span slot="footer" class="dialog-footer">
	      <el-button @click="mdEditVisible = false">取消</el-button>
	      <el-button type="primary" @click="confirmInput">确认</el-button>
	    </span>
	  </el-dialog>

    <div v-if="hasHistory()"
        style="position: fixed; left: 3%; bottom: 10%; width:50px; height:50px;
        background-color: #0072b2dd; border-radius: 300px;
        justify-content: center; justify-items: center; display: flex; cursor:pointer;
         box-shadow: 0 2px 4px rgba(0, 0, 0, .6), 0 0 6px rgba(0, 0, 0, .4)"
         onclick="history.back()"
    >
      <div style="margin: auto; font-weight: bolder; color: #ffcc00dd;">返回</div>
    </div>
  </div>

</template>

<script>

import {getPaper, getQuestions, postExaminee, getExamineeReport, signForm} from "../../api/index";
import FlipDown from "../../components/FlipDown.vue";
import Write from "../components/write.vue";
import Hearing from "../components/hearing.vue";
import Read from "../components/read.vue";
import Translate from "../components/translate.vue";
import Dictation from "../components/dictation.vue";
import MakeUp from "../components/makeUp.vue";
import Answer from "../components/answer.vue";
import Pack from "../components/pack.vue";
import Mistake from "../components/mistake.vue";
import Latex from "../components/latex/Home.vue";
import MarkdownRenderer from "../components/MarkdownRenderer.vue";
import MarkdownRendererHtml from "../components/MarkdownRendererHtml.vue";
import '../../utils/HZRecorder';
import TurndownService from 'turndown';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
let mediaRecorder;
let audioChunks = [];
export default {
  components: {
    FlipDown,
    Write,
    Hearing,
    Read,
    Translate,
    Dictation,
    MakeUp,
    Answer,
    Pack,
    Mistake,
	Latex,
	MarkdownRenderer,
	MarkdownRendererHtml
  },
  props: ["res"],
  data() {
    return {
      uiStyleCfg: null,
      title_img_url: '',

      bPdfImagesAvailable: false,
      btnText: "交卷",
      moveClass: "toleft",
      isUp: false,
      dataList: [],
      activeIndex: "",
      canNext: true,
      paperData: {
        total_score: "",
        title: "",
        questions_pdf: "",
        max_time: 0,
        examinee_id: "",
        paper_id: "",
        analysis_pdf: "",
      },
      answers: [],
      types_elapsed: [],
      questionsObjext: null,
      loading: false,
      ScantronLoading: false,
      max_time: null,
      stateType: null,

      toggleStart:false,
      toggleTit:false,
      totalSeconds: 50 * 60, // 20 minutes in seconds
      timer: null,
      type_code:"",
      textQuestionValues: {},
      explainImagesVisible: false,
      baogaoVisibleOld:false,
      baogaoVisible:false,
      baogaoData:{},
      baogaoDataV2: {},
      first_title:"",
      second_title:"",
      actVoice:"",
      voiceUrl:"",
      uploadIdArr:[],
      actTab:1,
      robotVisible:false,
      maxScore:0,
      myScore:0,
      robotText:"",
      robotVoice:"",
      voiceExplain:"",
      voiceExplainAudioUrl:"",
      isRobotPlay:false,
      isPause:false,
			examinee_id:"",
			paper_id:"",
			testgpt:"",
			baogaoDataOrig:{},
			cardVisible:false,
			upId:"",
			cardnum:[],
			cardDataNum:[],
			mdEditVisible:false,
			original:false,
			cropVisible: false,  // 控制裁剪弹窗的显示
			croppedImageSrc: '', // 存储需要裁剪的图片
			cropper: null,       // cropper 实例
			videoStream: null,   // 存储摄像头视频流
			cameraVisible: false, // 控制相机拍照弹窗
			uploadVisible: false, // 控制上传方式选择弹窗
			currentIndex: null,  // 当前操作的图片索引
			upId: null,          // 当前操作的题目 ID
			inputContent: '', // 用于暂存输入的内容
			blackBoard:'',
			mediaText:'',
			audioNum:null
    };
  },
  computed: {
    timeString: function() {
      const minutes = Math.floor(this.totalSeconds / 60);
      const seconds = this.totalSeconds % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    progressWidth() {
      let div = document.getElementsByClassName("list-img")[0];
      console.log("Dsf",div)
      return div.clientWidth
    },
  },
  created() {
  },
  async mounted() {
    this.uiStyleCfg = JSON.parse(this.res.data.ui_style_cfg.paper_cfg);
    console.log('this.uiStyleCfg', this.uiStyleCfg);

    if (window.innerWidth < window.innerHeight) {
      document.body.style.transformOrigin = 'top left';
      document.body.style.transform = 'rotate(90deg) translateY(-100%)';
      document.body.style.width = '100vh';
      document.body.style.height = '100vw';
      //  document.getElementById('hunan-paper-wrap').style.height = window.innerWidth + 'px';
    }

    window.addEventListener("beforeunload", (e) => {
      this.beforeunloadHandler(e);
    });

    this.bPdfImagesAvailable = this.res.data.questions_pdf_images && this.res.data.questions_pdf_images.length>0;
    if(this.bPdfImagesAvailable) {
      await this.paperList(false);
    }
    else {
      if (window.AdobeDC) {
        await this.paperList(true);
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          this.paperList(true);
        });
      }
    }

    //microphone
    let thiz = this;
    if (!thiz.audioRecorder) {
      await new Promise(resolve => {
        let config = {sampleBits: 16, sampleRate: 16000, format: 'wav'};
        window.HZRecorder.get((recorder) => {
          thiz.audioRecorder = recorder;
          resolve();
        }, config);
      });
    }
  },

  destroyed() {
    this.clearCountdown();
    // this.timeEnd(1);
  },

  methods: {
    hasHistory() {
      return history.length > 1;
    },
    // 获取试卷
    async paperList(need) {
      const res = this.res;
      if (res.code == 0) {
				if(this.$route.query.examinee_id == 0){
					this.updateExamineeId(res.data.examinee_id)
				}
        console.log(res);
        this.paperData.title = res.data.title;
        this.paperData.questions_pdf = res.data.questions_pdf;
        this.paperData.examinee_id = res.data.examinee_id;
        this.paperData.paper_id = res.data.paper_id;
        this.paperData.analysis_pdf = res.data.analysis_pdf;
		this.paperData.listening_score = res.data?.special?.listening_score;
		this.paperData.reading_score = res.data?.special?.reading_score;
		this.paperData.writing_score = res.data?.special?.writing_score;
		this.paperData.listening_std_level = res.data?.special?.listening_std_level;
		this.paperData.reading_std_level = res.data?.special?.reading_std_level;
		this.paperData.writing_std_level = res.data?.special?.writing_std_level;
        this.max_time = res.data.max_time;
        this.stateType = res.data.status;
        if(this.stateType=='done'){
          this.totalSeconds = 0;
        }else{
          this.totalSeconds = res.data.max_time*60;
        }
				this.paperData.card_img_num = res.data.card_img_num;
				for(let i=0;i<this.paperData.card_img_num;i++){
					this.cardnum.push({img:""})
				}
        this.paperData.user_score = res.data.user_score;
        this.paperData.total_score = res.data.total_score;
        const obj = res.data.question_types.find(
            (item) => item.type_code.indexOf('listening')==0
        );
        if (obj) {
          this.paperData.audioList = obj.music_mp3;
        }

        if (this.stateType == "done") {
          this.btnText = "总分";
          this.$store.commit("setExamination", true);
        }

        const times = new Date();
        this.paperData.max_time = new Date(
            times.getTime() + this.max_time * 60 * 1000
        );

        this.dataList = res.data.question_types;
        this.handleCheck(
            this.dataList[0]
        );

        if (need) {
          this.showPDF("adobe-dc-view", this.paperData.questions_pdf);
          !this.$store.state.examination &&
          this.showPDF("adobe-dc2-view", this.paperData.questions_pdf);
        }

      } else {
        this.$message.error("试卷获取失败 "+(res.msg||''));
      }
    },
		updateExamineeId(newExamineeId) {
			const currentRoute = this.$route;

			// 如果 examinee_id 没有变化，不做任何处理
			if (currentRoute.query.examinee_id === newExamineeId) {
				return;
			}

			// 更新查询参数并强制页面刷新
			this.$router.push({
				name: currentRoute.name,
				params: currentRoute.params, // 保持路由参数不变
				query: {
					...currentRoute.query,
					examinee_id: newExamineeId // 更新 examinee_id 查询参数
				}
			}).then(() => {
				// 成功导航后，强制刷新页面
				window.location.reload();
			}).catch(err => {
				// 捕获 NavigationDuplicated 错误，但不处理它
				if (err.name !== 'NavigationDuplicated') {
					console.error(err);
				}
			});
		},
    startAnimation(item) {
      clearInterval(this.interval);
      let totalTime = 60; // 默认倒计时时间为60秒
      item.percentage = 100;
      // 根据item.label值设置倒计时时间
      if (item.label === 'read') {
        totalTime = 120;
      } else if (item.label === 'answer') {
        totalTime = 60;
      } else if (item.label === 'express') {
        totalTime = 180;
      }

      let time = totalTime;

      this.interval = setInterval(() => {
        if (time > 0) {
          time--;
          item.percentage = (time / totalTime) * 100;
        } else {
          this.stopVoice();
        }
      }, 1000);
    },
    startVoice_bak(item){
      this.actVoice = item.id;
      this.startAnimation(item);
      navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.start();

            mediaRecorder.addEventListener('dataavailable', event => {
              audioChunks.push(event.data);
            });

            mediaRecorder.addEventListener('stop', () => {
              const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
              const audioFile = new File([audioBlob], "recording.wav", { type: 'audio/wav' });  // Blob转File
              this.uploadVoice(audioFile);
              audioChunks = [];
            });

            console.log("录音开始");
          })
          .catch(error => {
            console.error("录音失败:", error);
          });
    },
    startVoice(item) {
      this.actVoice = item.id;
      if (this.audioRecorder) {
        this.startAnimation(item);
        this.audioRecorder.start();
        this.audioRecording = true;
      }
    },
    stopVoice_bak(){
      if(mediaRecorder){
        mediaRecorder.stop();
        console.log("录音停止");
        clearInterval(this.interval);
      }
    },
    stopVoice(){
      if(this.audioRecorder && this.audioRecording){
        this.audioRecorder.stop();
        this.audioRecording = false;
        console.log("录音停止");
        clearInterval(this.interval);
        let blob = this.audioRecorder.getWavBlob();
        let file = new window.File([blob], "record.wav");
        this.uploadVoice(file);
      }
    },
    uploadVoice(audioFile) {
      const formData = new FormData();
      signForm(formData);
      formData.append("examinee_id", Number(this.paperData.examinee_id));
      formData.append("paper_id", Number(this.paperData.paper_id));
      formData.append("question_id", this.actVoice);
      formData.append("my_answer", audioFile, "record.wav");
      fetch('https://readapi.bluebirdabc.com/exam/en/postExamineeAudio', {
        method: 'POST',
        body: formData
      })
          .then(response => response.json())
          .then(data => {
            this.uploadIdArr.push(this.actVoice);
            this.answers.push({
              question_id:this.actVoice,
              my_answer:""
            })
            this.actVoice = "";
            console.log('文件上传成功:', data);
          })
          .catch(error => {
            this.actVoice = "";
            console.error('上传失败:', error);
          });
    },

    startTest(){
      this.toggleStart = true;
      this.startCountdown();
      if(this.dataList[0].music_mp3){
        this.playAudio(this.dataList[0].music_mp3[0]);
      }
    },
    clickPlay(item){
      item.isPlay = true;
      this.$forceUpdate()
      this.playAudio(item.voice_url);
    },
    clickStop(item){
      item.isPlay = false;
      this.$forceUpdate()
      const player = this.$refs.audioPlayer;
      if (player) {
        player.pause();  // 暂停音频
        player.currentTime = 0;  // 将音频的当前时间设置为0
      }
    },
    playAudio(audio) {
      // 获取音频元素引用并播放
      this.$refs.audioPlayer.src = audio;
      const player = this.$refs.audioPlayer;
      if (player) {
        player.play().catch(error => console.error("Audio play failed", error));
      }
    },
    tabClick(i){
      this.actTab = i;
      this.isRobotPlay = true;
      if(i==1){
        this.playAudio(this.robotVoice);
      }else if(i==2){
        this.playAudio(this.voiceExplainAudioUrl);
      }
    },
    handleAudioEnded() {
			if(this.audioNum||this.audioNum===0){
				this.audioNum++
				this.playAudioFun();
			}
      if(this.questionsObjext&&this.questionsObjext.questions){
        for(let item of this.questionsObjext.questions){
          item.isPlay = false;
        }
      }
      this.$forceUpdate()
      console.log("Audio ended. Handling events...");
    },
    selectItem(val,selItem){
      if(this.stateType=='done'){
        return
      }
      if(val == selItem.my_answer){//点击重复的
        return
      }
      let t = 0;
      for(let item of this.answers){
        if(item.question_id == selItem.id){//老题
					if(selItem.answer.split(' ').length>1){//多选
						this.updateAnswer(item, val)
					}else{
						item.my_answer = val;//覆盖老答案
					}
          t = 1;
        }
      }
      if(t==0){//新题
        this.answers.push({
          question_id:selItem.id,
          my_answer:val
        })
      }
      this.setAct();
    },
		isSelected(my_answer, subItem) {
			if(my_answer&&my_answer.indexOf(subItem)!=-1){
				return true
			}else{
				return false
			}
		},
		isCorrectOption(item, subItem) {
			return item.answer?.split(' ').includes(subItem);
		},
		isSelectedOption(item, subItem) {
			return item.my_answer?.split(' ').includes(subItem);
		},
		isCorrectAndSelected(item, subItem) {
			return this.isCorrectOption(item, subItem) && this.isSelectedOption(item, subItem);
		},
		isMultipleChoice(item) {
			// 通过判断答案中是否包含空格来确定是否为多选题
			return item.answer.includes(' ');
		},
		updateAnswer(item, val) {
			// 检查val是否已存在于item.my_answer中
			if (item.my_answer.includes(val)) {
					// 如果存在，则删除该选项
					item.my_answer = item.my_answer
							.split(' ') // 将字符串分割成数组
							.filter(answer => answer !== val) // 过滤掉要删除的选项
							.join(' '); // 重新组合成字符串
			} else {
					// 如果不存在，则添加该选项
					item.my_answer += ' ' + val;
			}
			console.dir(item.my_answer)
		},
    setAct(){//选色
      for(let item of this.questionsObjext.questions){
        for(let subItem of this.answers){
          if(item.id == subItem.question_id){
            item.my_answer = subItem.my_answer
          }
        }
      }
    },
    checkTit(item){
      this.robotVisible = true;
      this.maxScore = item.score;
      this.myScore = item.my_score;
      this.robotText = item.explain;
      this.robotVoice = item.explainAudioUrl;
      this.voiceExplain = item.voice_explain;
      this.voiceExplainAudioUrl = item.voice_explainAudioUrl;
      this.isRobotPlay = true;
      this.isPause = false;
      if(item.voice_explainAudioUrl){
        this.actTab = 2;
        this.playAudio(item.voice_explainAudioUrl);
      }else{
        this.actTab = 1;
        this.playAudio(item.explainAudioUrl);
      }
    },
		checkTitNew(item){
		  this.robotVisible = true;
			this.explain_v2 = item.explain_v2;
		  this.isRobotPlay = true;
		  this.isPause = false;
			this.audioNum = 0;
			this.mediaText = "";
			this.blackBoard = "";
			this.robotVoice = "";
			this.playAudioFun();
		},
		playAudioFun(){
			const item = this.explain_v2[this.audioNum];
			if(!item){
				return;
			}
			if(item.type == 'audio'){
				this.mediaText += item.displayText;
				this.robotVoice = item.mediaUrl;
				if(!this.robotVoice){
					this.audioNum++
					this.playAudioFun();
				}else{
					this.playAudio(this.robotVoice);
				}
			}else if(item.type == 'blackboard'){
				this.blackBoard += item.displayText+'<br>';
				console.dir(this.blackBoard)
				this.audioNum++
				this.playAudioFun();
			}else if(item.type == 'graphic'){
				this.$nextTick(()=>{
					this.injectJSXGraphCode(item.displayText)
					this.audioNum++
					this.playAudioFun();
				})
			}else if(item.type == 'image'){
				this.blackBoard += `<img class="jxgbox" src="${item.displayText}">`;
				this.audioNum++;
				this.playAudioFun();
			}
			this.scrollToBottom();
		},
		injectJSXGraphCode(dynamicJavascript) {
			if(!document.getElementById('jxgbox')){
				this.blackBoard += '<div class="displayText" id="jxgbox"></div>'
			}
			this.$nextTick(()=>{
				console.dir(document.getElementById('jxgbox'))
				// dynamicJavascript = "window.board = JXG.JSXGraph.initBoard('jxgbox', {\n" +
				//   "   boundingbox: [-10, 10, 10, -10],\n" +
				//   "   axis: true,\n" +
				//   "   keepAspectRatio: true\n" +
				//   "});\n" +
				//   "window.boardVars = {\n" +
				//   "   a:3, b:2\n" +
				//   "};\n" +
				//   "window.board.create('functiongraph', [function (x) {\n" +
				//   "   return Math.sqrt((x*x/window.boardVars.a/window.boardVars.a - 1)*window.boardVars.b*window.boardVars.b);\n" +
				//   "}], {strokeWidth: 2, strokeColor: '#0000ff'});\n" +
				//   "window.board.create('functiongraph', [function (x) {\n" +
				//   "   return -Math.sqrt((x*x/window.boardVars.a/window.boardVars.a - 1)*window.boardVars.b*window.boardVars.b);\n" +
				//   "}], {strokeWidth: 2, strokeColor: '#0000ff'});\n" +
				//   "window.board.create('line', [[-10, -10*window.boardVars.b/window.boardVars.a], [10, 10*window.boardVars.b/window.boardVars.a]], {strokeWidth: 1, dash: 2});\n" +
				//   "window.board.create('line', [[-10, 10*window.boardVars.b/window.boardVars.a], [10, -10*window.boardVars.b/window.boardVars.a]], {strokeWidth: 1, dash: 2});";
				// 	console.dir(dynamicJavascript)
				// let clearjs = `
				//   if(window.boardVars) {
				//     delete window.boardVars;
				//     window.boardVars = null;
				//   }
				//   if(window.board) {
				//     delete window.board;
				//     window.board = null;
				//   }
				// `;

				// if(this.jsxGraphNode) {
				//   this.jsxGraphNode.remove()
				// }

				// 创建一个 <script> 标签
				this.jsxGraphNode = document.createElement('script');

				// 将 JS 代码作为文本内容注入到 script 标签中
				this.jsxGraphNode.textContent = clearjs + dynamicJavascript;

				// 将 script 标签添加到当前文档的 <body> 中
				console.dir(this.jsxGraphNode)
				document.body.appendChild(this.jsxGraphNode);
				this.scrollToBottom();
			})
		},
		// 滚动到 div 的底部
		scrollToBottom() {
			console.dir(this.$refs)
			this.$nextTick(()=>{
				const blackBoard = this.$refs.blackBoard;
				const displayText = this.$refs.displayText;
				displayText.scrollTop = displayText.scrollHeight;
				console.log('displayText',displayText)
				console.log('blackBoard',blackBoard.$el)
				blackBoard.$el.scrollTop = blackBoard.$el.scrollHeight;
			})
		},
    robotPlay(){
      this.isRobotPlay = true;
      if(this.isPause){
        const player = this.$refs.audioPlayer;
        player.play()
      }else{
        if(this.actTab == 2){
          this.playAudio(this.voiceExplainAudioUrl);
        }else{
          this.playAudio(this.robotVoice);
        }
      }
    },
    robotPause(){
      this.isRobotPlay = false;
      this.isPause = true;
      const player = this.$refs.audioPlayer;
      if (player) {
        player.pause();  // 暂停音频
      }
    },
    closeRobot(){
      this.isRobotPlay = false;
      this.isPause = false;
      const player = this.$refs.audioPlayer;
      if (player) {
        player.pause();  // 暂停音频
        player.currentTime = 0;  // 将音频的当前时间设置为0
      }
    },
    setOptional(item){
      return item.split(" ");
    },
    startCountdown: function() {
      this.timer = setInterval(() => {
        if (this.totalSeconds > 0) {
          this.totalSeconds -= 1;
        } else {
          this.clearCountdown();
          this.timeEnd();
        }
      }, 1000);
    },
    clearCountdown: function() {
      clearInterval(this.timer);
      clearInterval(this.interval);
      this.timer = null;
      this.totalSeconds = 0;
    },
    // pdf展示
    showPDF(id, url) {
      console.log(id, url);
      const adobeDCView = new AdobeDC.View({
        clientId: "81211a7dfbe741499e88a71fbe4c8c48",
        divId: id,
      });
      adobeDCView.previewFile({
        content: {
          location: {
            url: url,
          },
        },
        metaData: { fileName: "北京奇峰天下科技有限公司技术支持.pdf" },
      });
    },

    // 点击题型获取题目
    async handleCheck(item) {
			const testPaper = this.$refs.testPaper;
			if (testPaper) {
				testPaper.scrollTop = 0; // 滚动到顶部
			}
      this.stopVoice();
      console.log("questionsObjext, item=",this.questionsObjext)
  	  console.log("textQuestionValues, item=",this.textQuestionValues)

      {
        let partImgKey = ''+this.res.data.category_id+'-'+item.type_name; // '18-Part 1'
        this.title_img_url = this.uiStyleCfg[partImgKey] || this.uiStyleCfg?.titleImg;
        this.type_code = item.type_code;
      }
      if (this.activeIndex === item.type_code && !this.$store.state.examination) return;
      if (this.canNext) {
        this.loading = true;
        this.activeIndex = item.type_code;
        const res = await getQuestions({
          user_id: Number(this.$route.query.user_id), //用户id, //用户id
          pid: this.$route.query.pid, //合作方id
          examinee_id: Number(this.paperData.examinee_id), //考试场次id
          paper_id: Number(this.paperData.paper_id), //试卷id
          type_code: item.type_code, //待获取的题型代码
        });
        this.questionsObjext = res.data;
		this.$nextTick(()=>{
			  if(this.questionsObjext.questions[0].label == 'AI-W'||this.questionsObjext.questions[0].label == 'AI-W-C'||this.questionsObjext.questions[0].label == 'AI-W-A'||this.questionsObjext.questions[0].label == 'science_open') {
				this.$refs.latex[0].save();
			  }
		})
        this.first_title = res.data.questions[0].first_title;
        this.second_title = res.data.questions[0].second_title;
        //填空题学生答案的临时缓存
        for(let quest of this.questionsObjext.questions) {
					for(let item of this.cardDataNum) {
						if(item.question_id == quest.id){
							quest.my_answer = item.answer||item.answer2
						}
					}
        	if((this.questionsObjext.type_code.indexOf('writing-writing')!=-1||this.questionsObjext.type_code == 'writing-complete'||this.questionsObjext.type_code == 'writing-answer'||quest.label === 'text'||quest.label === 'science_text'||quest.label === 'answer-text') && !this.textQuestionValues[quest.id]) {
        	  console.log("quest.my_answer",quest.my_answer);
						this.textQuestionValues[quest.id] = quest.my_answer||'';
        	}

					this.$nextTick(()=>{
						// if(this.$refs.latex&&this.$refs.latex[0]){
						// 	this.$refs.latex[0].init(this.textQuestionValues);
						// }
						if(this.textQuestionValues[quest.id]&&this.textQuestionValues[quest.id].md) {
							console.log("this.textQuestionValues[quest.id]",this.textQuestionValues[quest.id])
							if(quest.my_answer){
								this.textQuestionValues[quest.id].data = quest.my_answer
							}
							console.log("111",this.textQuestionValues[quest.id].data)
							this.$refs.latex[0].initHtml(this.textQuestionValues[quest.id].data);
						}else{
							if(this.$refs.latex){
								console.log("222",quest.my_answer)
								this.$refs.latex[0].initHtml(quest.my_answer);
							}
						}
					})
        }

        this.setAct();
        this.loading = false;
      } else {
        this.$message.warning("请先结束上一题作答");
      }
    },

    // 获取/修改 每大题答题时间
    handleEndTime(val) {
      console.log(val);
      const i = this.types_elapsed.findIndex(
          (item) => item.type_code === val.type_code
      );
      if (i >= 0) {
        this.types_elapsed.splice(i, 1);
      }
      this.types_elapsed.push(val);
    },

    // 获取/修改 每道题目的答案
    changeContents(val) {
      val.forEach((item) => {
        const i = this.answers.findIndex(
            (items) => items.question_id === item.question_id
        );
        if (i >= 0) {
          this.answers[i].my_answer = item.my_answer;
        } else {
          this.answers.push(item);
        }
      });
      console.log(this.answers);
    },
    //获取报告
    async openBaogao(){
      this.baogaoVisible = true;
      const res = await getExamineeReport({
        user_id: Number(this.$route.query.user_id), //用户id, //用户id
        pid: this.$route.query.pid, //合作方id
        examinee_id_list: [Number(this.paperData.examinee_id)], //考试场次id
      });
      this.baogaoData = res.data
      this.baogaoDataOrig = JSON.parse(JSON.stringify(res.data));
      const abilitiesObject = this.baogaoData.abilities.reduce((acc, current) => {
        acc[current.ability] = current;
        return acc;
      }, {});
      this.baogaoData.abilities = abilitiesObject;
      console.log('this.baogaoDataOrig=', this.baogaoDataOrig)
      console.log('this.baogaoData=', this.baogaoData)
    },
    async openExplainImages() {
      this.explainImagesVisible = true;
    },
    calc(num1,num2){
      return parseInt(num1/num2*100)
    },
    // 提交试卷
    handleSubmit() {
      this.$dialog.alert({
        showCancelButton:true,
        message: '是否确认提交试卷',
      })
          .then(() => {
            const player = this.$refs.audioPlayer;
            if (player) {
              player.pause()
            }
            this.clearCountdown();
            this.timeEnd();
          })
          .catch(() => {
            // on cancel
          })
    },
    async timeEnd(t) {
	  const turndownService = new TurndownService();
      this.ScantronLoading = true;
      //  const loading = this.$loading({
      // lock: true,
      // text: '正在拼命生成卷子解析',
      // spinner: 'el-icon-loading',
      // background: 'rgba(0, 0, 0, 0.7)'
      //  });
      // 合并填空题的答案
      for(let k in this.textQuestionValues) {
				if(this.textQuestionValues[k].md){
					this.textQuestionValues[k] = turndownService.turndown(this.textQuestionValues[k].data);
				}
        this.answers.push({
          question_id:k,
          my_answer:this.textQuestionValues[k]
        })
      }
			for (let item of this.cardDataNum) {
			  if (!this.answers.some(answer => answer.question_id == item.question_id)) { // 检查 question_id 是否在 this.answers 中已存在
			    this.answers.push({
			      question_id: item.question_id,
			      my_answer: item.answer || item.answer2
			    });
			  }
			}
			console.dir(this.answers);
      // 提交试卷请求
      const res = await postExaminee({
        user_id: Number(this.$route.query.user_id), //用户id, //用户id
        pid: this.$route.query.pid, //合作方id
        examinee_id: Number(this.paperData.examinee_id), //考试场次id
        paper_id: Number(this.paperData.paper_id), //试卷id
        answers: this.answers,
        types_elapsed: this.types_elapsed,
        type:t||''
      });
      if (res.code == 0) {
        this.ScantronLoading = false;
        // this.btnText = "总分";
        // this.$store.commit("setExamination", true);
        // this.paperData.user_score = res.data.total_score.type_score;
        // this.handleCheck(
        //   this.dataList[0].type_code
        // );
        // this.showPDF("adobe-dc-view", this.paperData.analysis_pdf);
//        window.location.reload();
        let p = 'examinee_id='+this.paperData.examinee_id;
        let url = window.location.href.replace('examinee_id=0', p);
        window.location.href = url;
        window.location.reload();
      } else {
        this.ScantronLoading = false;
        this.$message.error("提交失败请重新提交");
      }
    },
			openCard(item) {
				if(this.stateType=='done'){
					return
				}
				console.dir(item)
		    this.upId = item.id;
		    this.uploadVisible = true;
		  },
			openAllCard() {
		    this.cardVisible = true;
		  },
			openUpload(index) {
				  this.currentIndex = index;
				  this.uploadVisible = true;
			},
			checkCard(){
				console.dir(this.cardnum);
				if(this.questionsObjext) {
					for(let quest of this.questionsObjext.questions) {
					  for(let item of this.cardDataNum) {
							if(item.question_id == quest.id){
								quest.my_answer = item.answer||item.answer2
							}
					  }
						if((this.questionsObjext.type_code == 'writing-writing'||this.questionsObjext.type_code == 'writing-complete'||this.questionsObjext.type_code == 'writing-answer'||quest.label === 'text'||quest.label === 'science_text'||quest.label === 'answer-text') && !this.textQuestionValues[quest.id]) {
						  this.textQuestionValues[quest.id] = quest.my_answer||'';
						}
					}
				}
				this.cardVisible = false;
				console.log('this.questionsObjext,',this.questionsObjext)
			},
		  // 根据选择的上传方式
		  selectUpload(type) {
		    if (type === 'camera') {
		      this.openCamera();
		    } else if (type === 'picture') {
		      const input = document.createElement('input');
		      input.type = 'file';
		      input.accept = 'image/*';
		      input.onchange = (event) => {
		        const file = event.target.files[0];
		        if (file) {
		          const reader = new FileReader();
		          reader.onload = (e) => {
		            this.croppedImageSrc = e.target.result;
		            this.openCropper();  // 打开裁剪框
		          };
		          reader.readAsDataURL(file);
		        }
		      };
		      input.click();
		    } else if (type === 'pasteboard') {
					// 获取剪贴板图片
					navigator.clipboard.read().then((items) => {
						for (const item of items) {
							if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
								item.getType('image/png').then((blob) => {
									const file = new File([blob], "pasteboard-image.png", { type: blob.type });
									const reader = new FileReader();
									reader.onload = (e) => this.updateImage(e.target.result, file);
									reader.readAsDataURL(blob);
								});
								break;
							}
						}
					}).catch((error) => {
						console.error("Error accessing clipboard:", error);
					});
				}
		    this.uploadVisible = false;
		  },

		  // 打开相机
		  openCamera() {
		    this.cameraVisible = true;
		    navigator.mediaDevices.getUserMedia({
		      video: {
		        facingMode: 'environment'  // 使用后置摄像头
		      }
		    })
		    .then((stream) => {
		      this.videoStream = stream;
		      this.$refs.video.srcObject = stream;  // 显示摄像头实时画面
		    })
		    .catch((error) => {
		      console.error("Error accessing camera:", error);
		    });
		  },

		  // 关闭相机
		  closeCamera() {
		    if (this.videoStream) {
					this.videoStream.getTracks().forEach(track => track.stop());  // 停止摄像头流
					this.videoStream = null;
				}
				this.cameraVisible = false;
		  },

		  // 拍照并进入裁剪
		  capturePhoto() {
		    const video = this.$refs.video;
		    const canvas = document.createElement('canvas');
		    const context = canvas.getContext('2d');

		    // 设置 canvas 尺寸与视频一致
		    canvas.width = video.videoWidth;
		    canvas.height = video.videoHeight;

		    // 将视频画面绘制到 canvas 上
		    context.drawImage(video, 0, 0, canvas.width, canvas.height);
		    const imageUrl = canvas.toDataURL('image/png');

		    // 将图片传给裁剪框
		    this.croppedImageSrc = imageUrl;
		    this.closeCamera();  // 关闭相机
		    this.openCropper();  // 打开裁剪框
		  },

		  // 打开裁剪区域
		  openCropper() {
		    this.cropVisible = true;
		    this.$nextTick(() => {
		      const imageElement = this.$refs.cropperImage;
		      imageElement.src = this.croppedImageSrc; // 设置裁剪框的图片源
		      if (this.cropper) {
		        this.cropper.destroy();
		      }
		      this.cropper = new Cropper(imageElement, {
		        viewMode: 1,
		      });
		    });
		  },

		  // 裁剪并上传图片
		  cropAndUpload() {
		    this.cropper.getCroppedCanvas().toBlob((blob) => {
		      const file = new File([blob], "cropped-image.png", { type: "image/png" });
		      this.updateImage(URL.createObjectURL(blob), file);
		      this.cropVisible = false;
		    });
		  },

		  // 取消裁剪
		  cancelCrop() {
		    this.cropVisible = false;
		    if (this.cropper) {
		      this.cropper.destroy();
		      this.cropper = null;
		    }
		  },

		// 更新图片并上传
		updateImage(imageUrl, file) {
			if(this.currentIndex||this.currentIndex==0){
				this.postExamineeAnswerCardImage(imageUrl, file);
				return;
			}
			const formData = new FormData();
			signForm(formData);
			formData.append("examinee_id", Number(this.paperData.examinee_id));
			formData.append("paper_id", Number(this.paperData.paper_id));
			formData.append("sign", '');
			formData.append("image", file);

			const loading = this.$loading({
				lock: true,
				text: '正在拼命生成解析',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			fetch('https://readapi.bluebirdabc.com/exam/en/img2markdown', {
				method: 'POST',
				body: formData
			})
			.then(response => response.json())
			.then(data => {
				loading.close();
				if(!data.data){
					this.$message.error(data.msg);
					return
				}
				if(this.textQuestionValues[this.upId].md) {
					this.textQuestionValues[this.upId].data += data.data;
					this.$refs.latex[0].initHtml(this.textQuestionValues[this.upId].data);
				} else {
					this.textQuestionValues[this.upId] = data.data;
				}
				this.$forceUpdate();
				this.clickMd(this.upId)
				console.log('文件上传成功:', data);
			})
			.catch(error => {
				console.error('上传失败:', error);
			});
		},
		// 更新图片并上传
		postExamineeAnswerCardImage(imageUrl, file) {
			const formData = new FormData();
			signForm(formData);
			formData.append("examinee_id", Number(this.paperData.examinee_id));
			formData.append("paper_id", Number(this.paperData.paper_id));
			formData.append("token", localStorage.getItem('exam.token'));
			formData.append("sign", localStorage.getItem('exam.sign'));
			formData.append("card_idx", this.currentIndex);
			formData.append("card_img", file);

			const loading = this.$loading({
				lock: true,
				text: '正在拼命生成解析',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			fetch('https://readapi.bluebirdabc.com/exam/en/postExamineeAnswerCardImage', {
				method: 'POST',
				body: formData
			})
			.then(response => response.json())
			.then(data => {
				loading.close();
				if(!data.data){
					this.$alert(data.msg, '提示', {confirmButtonText: '确定'});
				}else{
					data.data.forEach(newItem => {
					    const existingItem = this.cardDataNum.find(item => item.question_id == newItem.question_id);
					    if (existingItem) {
					        // 更新existingItem的所有字段
					        Object.assign(existingItem, newItem);
					    } else {
					        // 如果不存在，则添加新的子项
					        this.cardDataNum.push(newItem);
					    }
					});
					console.log('this.cardDataNum',this.cardDataNum)
					this.$set(this.cardnum, this.currentIndex, { img: imageUrl });
				}
				console.log('答题卡解析成功:', data);
			})
			.catch(error => {
				console.error('上传失败:', error);
			});
		},
		mdEdit(item) {
			this.upId = item.id;
			this.inputContent = this.textQuestionValues[item.id] || ''; // 初始化弹窗中的输入内容
			this.mdEditVisible = true;
		},
		confirmInput() {
			// 将暂存的内容赋值给实际的数据字段
			console.dir(this.inputContent)
			this.textQuestionValues[this.upId] = this.inputContent;
			// this.$forceUpdate();
			this.mdEditVisible = false; // 关闭弹窗
		},
		clickMd(id){
			if(!this.original){
				this.original = this.textQuestionValues[id].data;
				console.dir(this.original)
				this.$nextTick(()=>{
					window.MathJax.typesetPromise();
				})
			}else{
				document.querySelectorAll('#text-' + id + ' .w-e-text').forEach(element => {
				  console.dir(element); // 输出找到的 .w-e-text 元素
				  const original = element.getAttribute('data-original');
					console.dir(original)
				  element.innerHTML = this.original || element.textContent;
					this.original = "";
				});
			}
		}
  },
};
</script>
<style lang="less" scoped>

.child-wrap{
  display: flex;
  height: 100%;
  position: relative;
  .flex-1{
    flex: 1;
  }
  .flex-2{
    flex: 4;
    padding-left: 5%;
    padding-right: 5%;
  }
  .modal{
    position: absolute;
    z-index: 10;
    transform: translateZ(10px);
    width: 100%;
    height: 100%;
    background-color: rgba(115, 149, 221, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    .start{
      position: absolute;
      top:10%;
      cursor: pointer;
    }
  }
  .modals{
    background-color: rgba(0, 0, 0, .3);
  }
  #adobe-dc-view{
    height: 100%;
  }
  .test-paper{
    height: 100%;
    flex: 3;
    overflow: auto;
  }
  .banner{
    //		width:878px;
    //		height:234px;
    width:100%;
    height:11vw;
    background-color: #FAFFF9;
    position: relative;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		margin-bottom: 10px;
    .banner-title{
      // width: 266px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 20px;
    }
	.banner-photo{
		width: 266px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 5px;
		cursor: pointer;
	}
    .banner-timer{
      width: 82px;
      position: absolute;
      right: 100px;
      top: 80px;
    }
    .time-cont{
      background-color: #000;
      color: white;
      font-size: 32px;
      font-weight: bold;
      width:174px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      position: absolute;
      right: 40px;
      bottom: 80px;
    }
    .start-img{
      position: absolute;
      right: 40px;
      bottom: 10px;
      cursor: pointer;
      width:174px;
      height: 60px;
    }
    .banner-text{
      position: absolute;
      left: 17%;
      top:77%;
      div{
        font-size: 24px;
      }
      ul{
        font-size: 22px;
        width: 580px;
        max-height: 100px;
        overflow-y: auto;
        .banner-cir-div{
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .score-cont{
    display: flex;
    justify-content: center;
    background-color: #FAFFF9;
    .score{
      margin:0 10px;
      position: relative;
      width: 320px;
      height: 91px;
      .score-img{
        position:absolute;
        left:0;
        height: 91px;
      }
      .score-text{
        color:black;
        font-size:2vw;
        font-weight:bolder;
        position:absolute;
        right:30px;
        bottom:15px;
      }
    }
  }
  .baogao-cont{
    display: flex;
    justify-content: center;
    background-color: #FAFFF9;
    padding: 10px 0;
    img{
      width:228px;
      height: 65px;
      cursor: pointer;
    }
  }
  .ket{
	  .score-cont{
	    display: flex;
	    justify-content: right;
		padding-right: 20px;
	    background-color: #FAFFF9;
	    .score{
	      margin:0 10px;
	      position: relative;
			height: 91px;
			width: 557px;
	      .score-img{
	        position:absolute;
	        right:0;
	        height: 91px;
			width: 557px;
	      }
	      .score-text{
	        color:black;
	        font-size:28px;
	        font-weight:800;
	        position:absolute;
	        right:370px;
	        bottom:16px;
			color: #2B882C;
	      }
				.score-text-org{
					position: absolute;
					right: 2.4%;
					top: 48px;
					font-size: 22px;
					font-weight: bold;
					color: #2B882C;
					width: 130px;
					text-align: center;
				}
	    }
		.reading{

		}
		.writing{
			width: 100%;
		}
	  }
	  .baogao-cont{
	    display: flex;
	    justify-content: right;
	    background-color: #FAFFF9;
	    padding: 10px 35px;
	    img{
	      width:266px;
	      height: 75px;
	      cursor: pointer;
	    }
	  }
  }
  .ket-read{
  	  .score-cont{
  	    display: flex;
  	    justify-content: right;
  		padding:0 40px;
		align-items: center;
  	    background-color: #FAFFF9;
		img{
			width: 100%;
		}
  	    .score-read{
  	      margin:0 10px;
  	      position: relative;
		  width: 50%;
  	      .score-text{
			position: absolute;
			right: 64%;
			top: 1.35vw;
			font-size: 29px;
			font-weight: bold;
			color: #2B882C;
  	      }
  	    }
				.score-text-org{
					position: absolute;
					right: 2.7%;
					top: 42px;
					font-size: 20px;
					font-weight: bold;
					color: #2B882C;
					width: 110px;
					text-align: center;
				}
		.score-img{
		  width: 35%;
		  cursor: pointer;
		}
  	  }
  	  .baogao-cont{
  	    display: flex;
  	    justify-content: right;
  	    background-color: #FAFFF9;
  	    padding: 10px 35px;
		position: relative;
  	    img{
  	      width:86%;
		  height: auto;
  	    }
		.score-text{
			position: absolute;
			right: 58.5%;
			top: 2.25vw;
			font-size: 32px;
			font-weight: bold;
			color: #2B882C;
		}
		.score-text-org{
			position: absolute;
			right: 5.5%;
			text-align: center;
			top: 68px;
			font-size: 22px;
			font-weight: bold;
			color: #2B882C;
			width: 200px;
		}
  	  }
  }
  .paper-cont{
    display: flex;
    height: calc(100% - 234px);
    padding: 0 20px 0 60px;
    background-color: #FAFFF9;
    .paper-ul{
      width: 80px;
      .ul-item{
        // background-color: #ED7D31;
        margin: 5px 0;
        width: 80px;
        height: 185px;
        font-size: 30px;
        writing-mode: vertical-rl; /* 或者使用 vertical-lr */
        // color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
		clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 20% 100%, 0% 90%, 0% 10%);
      }
      .noactUl{
        background-color: #C9C9C9!important;
      }
    }
    .paper-list{
      flex: 1;
      margin-left: 20px;
      padding-top: 10px;
      .list-item{
        margin-bottom: 50px;
        padding-right: 20px;
        .areaClass{
					position: relative;
          .areaAnswer{
            border: 5px solid #000;
            border-radius: 5px;
            font-weight: 900;
            font-size: 18px;
            height: 600px;
						overflow: auto;
            width: 100%;
            padding: 5px 10px;
            img{
              width: 40px;
              height: 40px;
							position: absolute;
							bottom: 70px;
							right: 20px;
            }
          }
          .areaInput{
            border: 5px solid #000;
            border-radius: 5px;
            font-weight: 900;
            height: 21.5em;
            padding: 5px 10px;
			/* 实现横线效果 */
			background: linear-gradient(transparent 95%, #424949 0);
			line-height: 2em;
			background-size: 100% 2em;
			background-attachment: local; /*  这里需要根据 textarea 的内容进行滚动 */
			/* textarea样式修改 */
			width: 100%;
			resize: none;
			font-size: 1.5em;
			font-family: inherit;
			outline: none;
			color: #2874A6;
			word-break: break-all;
			overflow-x: hidden;
          }
          .hunanjx{
            border-radius: 10px;
            margin-top: 5px;
            background-color: #18605A;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 22px;
            width: 100px;
            height: 50px;
            padding: 0 20px;
            cursor: pointer;
            word-wrap: break-word;
          }
        }
        .tit-img{
          width:30%;
        }
        .abc{
          border: 5px solid #000;
          border-radius: 5px;
          display: flex;
          margin-top: -5px;
          .item-index{
            width: 140px;
          //   background-color: rgb(178, 221, 223);
          }
          .item-btn{
            border-right: 1px solid #000;
            padding: 2px 0 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: 900;
            font-size: 18px;
            position: relative;
            .pf{
              position: absolute;
              bottom:0px;
              right:0px;
              height:40px;
            }
          }
          .sel-btn{
            width: 30px;
            height: 30px;
            border-radius: 100%;
            cursor: pointer;
            border: 2px solid #000;
          }
          .actBtn{
            background-color: #000;
            color: #fff;
          }
          .tkIndex{
            padding: 10px 0;
			cursor: pointer;
          }
          .pf-cont{
            flex: 1;
            padding: 10px 0;
          }
          .tkInput{
            border: 0;
            padding: 10px 0;
          }
          .hunanjx{
            background-color: #18605A;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 22px;
            width: 44px;
            padding: 0 20px;
            cursor: pointer;
            word-wrap: break-word;
          }
        }
        .s60{
          display: flex;
          align-items: center;
          img{
            width: 100px;
            height: 100px;
            cursor: pointer;
            border-radius: 100%;
            box-sizing: border-box;
          }
          .voiceBtn{
            position: relative;
            width: 100px;
            height: 100px;
            .bor{
              position: absolute;
            }
            .actimg{
              position: absolute;
              z-index: 1;
              width: 90px;
              height: 90px;
              left: 5px;
              top:5px;
            }
          }
          .s60-txt{
            background-color: #fff;
            height: 90px;
            overflow: auto;
            border: 5px solid #000;
            padding: 5px 10px;
            border-radius: 8px 0 0 8px;
            margin-left: 20px;
          }
          @media screen and (min-width: 0px) and (max-width: 540px) {
            img{
              width: 30px;
              height: 30px;
            }
            .voiceBtn{
              width: 30px;
              height: 30px;
              .actimg{
                width: 24px;
                height: 24px;
                left: 3px;
                top:3px;
              }
            }
            .s60-txt{
              height: 30px;
              padding: 3px 8px;
              border-radius: 4px 0 0 4px;
              margin-left: 10px;
              font-size: 12px;
            }
          }
          @media screen and (min-width: 541px) and (max-width: 900px) {
            img{
              width: 50px;
              height: 50px;
            }
            .voiceBtn{
              width: 50px;
              height: 50px;
              .actimg{
                width: 42px;
                height: 42px;
                left: 4px;
                top:4px;
              }
            }
            .s60-txt{
              height: 50px;
              padding: 3px 8px;
              border-radius: 4px 0 0 4px;
              margin-left: 13px;
              font-size: 13px;
            }
          }
          .checkImg{
            border: 5px solid #FFBE00;
          }
          .hunanjx{
            background-color: #18605A;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 22px;
            width: 44px;
            padding: 0 20px;
            cursor: pointer;
            border: 5px solid #000;
            border-left: 0;
            word-wrap: break-word;
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }
  }
  .cur{
    cursor: pointer;
  }
  .robot-wrap{
    display: flex;
    align-items: stretch;
    .img-cont{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      margin-left:20px;
    }
    .play,.stop,.pause{
      width: 120px;
      height: 120px;
      margin-bottom: 50px;
      cursor: pointer;
			&:hover{
					opacity: .6;
			}
    }
    .robot-img{
      width:182px;
      height:258px;
      @media screen and (min-width: 0px) and (max-width: 540px) {
        width:10vw;
        height:14vw;
        margin-right:3vw;
      }
    }
    .robot-text0{
      background-image: url('../../assets/hunan-paper/area.png');
      width:1113px;
      height:308px;
      padding: 33px 35px 50px 35px;
      box-sizing: border-box;
      background-size: 100% 100%;
    }
    .robot-text{
      // background-image: url('../../assets/hunan-paper/area2.png');
      width:100%;
      min-height:624px;
      padding: 30px 35px 0 35px;
      box-sizing: border-box;
      position: relative;
      background-size: 100% 100%;
			font-size: 24px;
			line-height: 36px;
			text-align: justify;
			display: flex;
			flex-direction: column;
			.blackBoard{
				background: #FBFAE7;
				color: #2A5C0C;
				height:450px;
				padding: 24px 36px 40px;
				overflow: auto;
				text-align: left;
				::v-deep p{
					max-width: 800px;
					text-align: left;
				}
			}
			.displayText{
				background: #e1ecd6;
				color: #2A5C0C;
				height:150px;
				overflow: auto;
				margin-top: 16px;
				padding: 24px 36px 40px;
			}
      @media screen and (min-width: 0px) and (max-width: 540px) {
        flex:1;
        height: 50vw;
        padding: 5vw 5vw 7vw 5vw;
      }
      .btn-group{
        display: flex;
        position: absolute;
        top: -40px;
        width: calc(100% - 45px);
        .tab-btn{
          width: 120px;
          height: 40px;
          border-radius: 5px 5px 0 0;
          margin-left: 5px;
          background-color: #AFABAB;
          color: #fff;
          font-weight: bold;
          font-size:18px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .act-tab{
          background-color: #93CD70;
        }
        .score-btn{
          height: 30px;
          border-radius: 5px 5px 0 0;
          margin-left: auto;
          margin-right: 10px;
          background-color: #FFB929;
          color: #fff;
          font-size:20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          padding: 5px 20px;
          span{
            margin: 3px 10px 0;
          }
        }
      }
    }
  }
  .baogao-wrap{
    width:60vh;
    height:90vh;
    background-size: 100% 100%;
    position: relative;
    font-size: 1.5vh;
    color: #000;
	.baogaoLogo{
		width: 130px;
		height: 130px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 20vh;
	}
    div{
      width: 100%;
      position: absolute;
      text-align: left;
      left: 8vh;
    }
    ul{
      overflow-y: auto;
      position: absolute;
      list-style-type: circle;
      width: 45vh;
      left: 8vh;
      li{
        margin: 10px;
      }
    }
    .wel1{
      top: 31vh;
      left: 0;
      text-align: center;
    }
    .wel2{
      top: 33.7vh;
      left: 0;
      text-align: center;
    }
  }
  .Listening-bg{
    background-image: url('../../assets/hunan-home/listening-UI-nologo.png');
    ul{
      height: 12vh;
      top:65vh;
    }
    .ans1{
      top: 40vh;
    }
    .ans2{
      top: 46vh;
    }
    .ans3{
      top: 52vh;
    }
    .ans4{
      top: 57.6vh;
    }
    .end1{
      top: 78vh;
      font-weight: bold;
      text-align: left;
    }
  }
  .ReadingWriting-bg{
    background-image: url('../../assets/hunan-home/Reading & Writing-UI-nologo.png');
    ul{
      height: 12vh;
      top:69vh;
    }
    .ans1{
      display: none;
    }
    .ans2{
      top: 44vh;
    }
    .ans3{
      top: 52.5vh;
    }
    .ans4{
      top: 60.5vh;
    }
    .level1{
      display: none;
    }
    .end1{
      top: 81vh;
      font-weight: bold;
      text-align: left;
    }
  }
  .UseOfEnglish-bg{
    background-image: url('../../assets/hunan-home/Use of English-UI-nologo.png');
    ul{
      height: 12vh;
      top:66vh;
    }
    .ans1{
      top: 40vh;
      display: none;
    }
    .ans2{
      top: 46vh;
    }
    .ans3{
      top: 52vh;
      display: none;
    }
    .ans4{
      top: 57.6vh;
    }
    .level1{
      display: none;
    }
    .level3{
      display: none;
    }
    .end1{
      top: 78vh;
      font-weight: bold;
      text-align: left;
    }
  }
}
.card-wrap{
	display: flex;
	.card-item{
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-right: 30px;
		.card-no{
			margin-bottom: 20px;
			font-size: 20px;
			font-weight: bold;
		}
		.el-button{
			width: 100px;
			margin-top: 20px;
		}
		img{
			width: 200px;
			cursor: pointer;
		}
	}
	.checkCard{
		margin-top: 30px;
	}
}
.upload-wrap{
	img{
		width: 100px;
		margin-right: 20px;
		cursor: pointer;
	}
}
.circle{
  background-color: #000;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-left: 10px;
}
.my-textarea {
  border-style:none;
  background-color:#00000000;
  outline:none;
  resize:none;
  font-size: 20px
}
.latexMd{
	background-color: #fff;
	height: calc(100% - 74px);
	width: calc(100% - 4px);;
	position: absolute;
	z-index: -1;
	top: 72px;
	left: 2px;
}
.latexMdAct{
	z-index: 10;
}
.my-textarea::-webkit-scrollbar{
  width:5px;
}
/* 添加滑块样式 */
.my-textarea::-webkit-scrollbar-thumb {
  background-color: #8c8c8c;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius: 3px;
}

/* 添加角度样式 */
.my-textarea::-webkit-scrollbar-corner {
  background-color: #f5f5f5;
}
/* 添加轨道样式 */
#explainBox::-webkit-scrollbar-track {
  background-color: #e4e4e3;
  border-radius: 3px;
}
#explainBox::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 3px;
}
::v-deep .robot-window{
	.el-dialog__body{
	  padding-top: 30px;
		height: 84%;
		.robot-wrap{
			height: 100%;
			.robot-text{
				height: 100%;
			}
		}
	}
	.close-btn{
		width: 209px;
		height: 64px;
		position: absolute;
		background-image: url(../../assets/closejx.png);
		background-repeat: no-repeat;
		background-size: contain;
		top:20px;
		left: 50%;
		transform: translateX(-50%);
		cursor: pointer;
		&:hover{
				background-image: url(../../assets/closejx-h.png);
		}
	}
	position: relative;
	background: url(../../assets/Rectangle@2x.png) no-repeat;
	background-size: 100% 100%;
	box-shadow:none;
  @media screen and (min-width: 0px) and (max-width: 540px) {
    margin-top:20px!important;
  }
}
::v-deep #jxgbox{
	width: 400px;
	height: 300px;
}
::v-deep .baogao-window{
  margin-top: 20px!important;
  .el-dialog__body{
    padding: 0;
  }
}
::v-deep .jiaoben-window{
	.el-dialog{
		height: 85vh;
	}
  .el-dialog__body{
    padding: 0;
  }
}
</style>
